import _ from 'lodash'
import type { IUnfinishedTasks } from './types'
import type { UnfinishedTasksHandlers } from '../types'

export const UnfinishedTasks = ({ unfinishedTasks }: UnfinishedTasksHandlers): IUnfinishedTasks => ({
	add: (name) => {
		const id = _.uniqueId()

		unfinishedTasks.add(id, name)

		return () => {
			unfinishedTasks.remove(id)
		}
	},
})
